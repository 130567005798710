import React from "react"
import SearchResult from "./search-result"

const results = [
  {
    recurso: "Libro Ritmo y compás",
    capitulo: "Referencias discográficas",
    coincidencias: 58,
  },
  {
    recurso: "Libro Ritmo y compás",
    capitulo: "VI.2. BULERÍAS",
    coincidencias: 7,
  },
  {
    recurso: "Libro Ritmo y compás",
    capitulo: "VI.1 SOLEÁ / ALEGRÍA",
    coincidencias: 5,
  },
  {
    recurso: "Libro Ritmo y compás",
    capitulo: "XII.2.1 El «medio compás»",
    coincidencias: 3,
  },
]

function SearchResultList() {
  return (
    <div className="search-result-list py-4 space-y-4">
      {results.map(props => (
        <SearchResult {...props} />
      ))}
    </div>
  )
}

export default SearchResultList
