import React from "react"
import { Link } from "gatsby"
import _ from "lodash"
import { motion } from "framer-motion"

export default function PlanCard({ plan, borderColor }) {
  const { titulo, duracion } = plan

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      transition={{ ease: "easeInOut", duration: 0.1 }}
      className="flex flex-col"
    >
      <Link
        to={`/planes-de-lectura/${_.kebabCase(titulo)}`}
        className="flex justify-center flex-col items-center rounded aspect-video bg-white"
        style={{
          backgroundColor: `${borderColor}`,
          // boxShadow: `${borderColor} 0px 4px 24px`,
        }}
      >
        <h2 className={`font-semibold mb-4 leading-tight text-center`}>
          {titulo}
        </h2>
        <h6 className="text-xs text-center font-semibold text-gray-500">
          {duracion} pp.
        </h6>
      </Link>
    </motion.div>
  )
}
