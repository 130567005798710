import React from "react"

function Actividad({ text }) {
  return (
    <div>
      <p
        className="leading-normal"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default Actividad
