import React from "react"

function Comment({ image, displayName, children }) {
  return (
    <div className="flex py-4 gap-3">
      {image && (
        <div className="py-2">
          <img src={image} alt={displayName} className="w-6 h-6 rounded-full" />
        </div>
      )}
      <div className="p-2 flex-1 border rounded leading-normal">{children}</div>
    </div>
  )
}

export default Comment
