import React, { useLayoutEffect, createRef } from "react"
import { gsap } from "gsap"
import * as E from "./elements"

import img1 from "../../../static/ritmoycompas.jpg"
import img2 from "../../../static/palmasflamencas.jpg"
import avatar from "../../../static/randomUser.jpg"
import recurso1 from "../../../static/rec10.png"
import recurso2 from "../../../static/rec11.jpeg"
import recurso3 from "../../../static/rec12.jpeg"
import recurso4 from "../../../static/rec13.jpeg"

// const DASHBOARD = ".dashboard"
// const POINTER = ".cursor"
// const CONTENT = ".dashboard__content"
// const SELECTED_TEXT = ".selected-text"
// const SELECTOR = ".dashboard__selector"
// const HIGHLIGHT_TRIGGER = ".dashboard__selector button:first-of-type"
// const HIGHLIGHT_SAVE = ".dashboard__aside button:last-of-type"
// const HIGHLIGHT_PANEL = ".dashboard__aside"
const DRAWER_PANEL = ".drawer-panel"
const DRAWER_INNER = ".drawer__inner"
const SUGGESTED_LIST = ".suggested-list"
const DRAWER_BACKGROUND = ".drawer-background"
const DELETE_RESULTS = ".delete-results"
const SEARCH_TEXT_CHARACTER = ".search-text.input span"
const RESULT_01_TEXT_CHARACTER = ".search-text.one span"
const RESULT_02_TEXT_CHARACTER = ".search-text.two span"
const RESULT_03_TEXT_CHARACTER = ".search-text.three span"

const TYPE_SPEED = 0.08

function HeroWeb({ className }) {
  const dashboard = createRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const master = gsap.timeline({ repeat: 10, repeatDelay: 2 })

      master
        .to(
          DRAWER_BACKGROUND,
          {
            opacity: 0.4,
          },
          "5"
        )
        .from(
          DRAWER_PANEL,
          {
            xPercent: -100,
            duration: 0.3,
          },
          "<"
        )
        .from(
          DRAWER_INNER,
          {
            opacity: 0,
          },
          "<60%"
        )
        .to(
          SEARCH_TEXT_CHARACTER,
          {
            display: "inline-block",
            stagger: TYPE_SPEED,
          },
          "<1"
        )
        .from(
          DELETE_RESULTS,
          {
            opacity: 0,
          },
          "<"
        )
        .from(
          SUGGESTED_LIST,
          {
            opacity: 0,
          },
          "<"
        )
        .to(
          RESULT_01_TEXT_CHARACTER,
          {
            backgroundColor: "yellow",
            duration: 0,
            stagger: TYPE_SPEED,
          },
          "<"
        )
        .to(
          RESULT_02_TEXT_CHARACTER,
          {
            backgroundColor: "yellow",
            duration: 0,
            stagger: TYPE_SPEED,
          },
          "<"
        )
        .to(
          RESULT_03_TEXT_CHARACTER,
          {
            backgroundColor: "yellow",
            duration: 0,
            stagger: TYPE_SPEED,
          },
          "<"
        )
        .to(
          SUGGESTED_LIST,
          {
            display: "none",
          },
          ">1"
        )
        .from(".search-result-list", {
          opacity: 0,
        })
    }, dashboard)
    return () => ctx.revert()
  }, [dashboard])

  return (
    <>
      <E.Layout ref={dashboard} className={className}>
        <E.Drawer />
        <div className="pl-2 pr-4 max-w-4xl mx-auto text-xs">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <E.Section
              title="Recursos activos"
              images={[img1, img2, recurso1]}
            ></E.Section>
            <E.Section
              title="Recursos completados"
              images={[recurso2, recurso3, recurso4]}
            ></E.Section>
          </div>
          <E.Section title="Actividad reciente" className="space-y-8">
            <E.Destacado
              chapter="II.1. Los valores temporales como signos métricos"
              recurso="Ritmo y compás"
            >
              <p>
                Pellentesque non metus congue, euismod tellus eget, auctor odio.
                Etiam ipsum lorem, sagittis vitae pellentesque a, laoreet sed
                dolor. Mauris iaculis ligula sed mollis lobortis. Curabitur
                congue ac diam in consequat. Integer augue ipsum, luctus quis
                scelerisque sed, pretium et lorem. Mauris eget fermentum quam,
                ut vehicula massa. Nam vitae cursus nulla.
              </p>
            </E.Destacado>
            <E.Actividad text="Has finalizado el capítulo <b>I.1. Conceptos preliminares</b> del libro <b>Ritmo y compás</b>" />
            <E.Destacado
              chapter="II.1. Los valores temporales como signos métricos"
              recurso="Ritmo y compás"
            >
              <p>
                Mauris iaculis ligula sed mollis lobortis. Curabitur congue ac
                diam in consequat. Integer augue ipsum, luctus quis scelerisque
                sed, pretium et lorem. Mauris eget fermentum quam, ut vehicula
                massa. Nam vitae cursus nulla.
              </p>
              <E.Comment image={avatar}>
                <p>
                  Pellentesque non metus congue, euismod tellus eget, auctor
                  odio. Etiam ipsum lorem, sagittis vitae pellentesque a,
                  laoreet sed dolor. Mauris iaculis ligula sed mollis lobortis.
                  Curabitur congue ac diam in consequat. Integer augue ipsum,
                  luctus quis scelerisque sed, pretium et lorem. Mauris eget
                  fermentum quam, ut vehicula massa. Nam vitae cursus nulla.
                </p>
              </E.Comment>
            </E.Destacado>
          </E.Section>
        </div>
      </E.Layout>
    </>
  )
}

export default HeroWeb
