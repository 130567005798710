import React, { useLayoutEffect } from "react"
import img from "../../../../static/1FigI10.jpeg"

function Book() {
  useLayoutEffect(() => {
    const selectedText = document.querySelector(".selected-text")

    const splitedMarkup = selectedText.innerHTML
      .split("")
      .map(character => `<mark>${character}</mark>`)

    selectedText.innerHTML = splitedMarkup.join("")
  }, [])

  return (
    <>
      <div class="px-2">
        <header class="flex justify-between items-center sticky top-0 py-[0.5em] pr-2">
          <div class="relative space-x-2 flex-1">
            <button class="p-2 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="8" y1="6" x2="21" y2="6"></line>
                <line x1="8" y1="12" x2="21" y2="12"></line>
                <line x1="8" y1="18" x2="21" y2="18"></line>
                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                <line x1="3" y1="18" x2="3.01" y2="18"></line>
              </svg>
            </button>
            <div class="absolute top-1/2 transform -translate-y-1/2 left-[2.5em] text-left">
              <h2 class="text-[0.6em]">Ritmo y compás</h2>
              <h3 class="font-medium text-[0.8em]">
                I. Conceptos preliminares
              </h3>
            </div>
          </div>
          <h4 class="font-medium text-[0.8em]">I.4. Tipos rítmicos</h4>
        </header>
        <div class="dashboard__content max-w-[50em] mx-auto text-justify space-y-3 text-[0.8em] -mt-7 indent-6">
          <p>
            A diferencia de los tipos relativos al inicio rítmico, se distingue
            un solo tipo rítmico final cuando no hay coincidencia entre nota y
            acento (femenino). Por tanto, si bien se contempla la posibilidad de
            que el motivo termine después del último acento métrico, no se
            valora la opción de que termine antes del acento. Veamos como
            resumen una muestra de todos los tipos rítmicos mediante un
            fragmento de Sevillana:
            <sup class="text-blue-600 text-xxs">13</sup>
          </p>
          <p>
            Fig.I.10. SEVILLANA: La Niña de los Peines, 1946
            <span class="text-blue-600">[179/1'19"]</span>
          </p>
          <img
            src={img}
            alt="tab"
            class="w-full px-10 mix-blend-darken opacity-80"
          />
          <p>
            Cuando se escriben en compás ternario (3/4), ambos versos son de
            inicio anacrúsico (a); en cambio, en compás binario (2/4), que se
            acomoda mejor a las sílabas tónicas del texto, el segundo verso
            resulta tético (t). En cuanto a los finales rítmicos, la elección de
            compás binario o ternario también condiciona el resultado: en 3/4,
            el final es masculino (m); en 2/4, pasa a ser femenino (f).
          </p>
          <h3 class="pt-5 pb-2 font-semibold text-[1.2em]">
            I.5. Polirritmia y polimetría
          </h3>
          <p>
            <span class="selected-text">
              En términos generales, podemos definir como polirrítmica toda
              simultaneidad de ritmos distintos.
            </span>
            &nbsp; Ahora bien, conviene diferenciar aquellas combinaciones que
            sólo implican una disparidad rítmica de aquéllas que realmente
            entrañan compases distintos. En esta materia, la teoría musical
            académica distingue dos modalidades, la polirritmia complementaria y
            la polirritmia contradictoria:
            <sup class="text-blue-600 text-xxs">14</sup>
          </p>
          <h4 class="font-semibold">1) Polirritmia complementaria</h4>
          <p class="indent-0 pl-6">
            Se considera complementaria toda simultaneidad de ritmos distintos
            que, no obstante, comparten el mismo compás. Las palmas flamencas
            son un buen ejemplo, pues se basan en patrones deliberadamente
            distintos, pero articulados sobre la misma pauta métrica.
          </p>
        </div>
      </div>
      <div class="dashboard__selector absolute bottom-0 w-full text-[.8em] px-10">
        <div class="p-2 flex items-center justify-center space-x-2 max-w-sm mx-auto">
          <button class="flex justify-center flex-1 py-1 px-3 bg-fire-500 text-white">
            Destacar
          </button>
          <button class="flex justify-center flex-1 py-1 px-3 bg-fire-500 text-white">
            Nueva nota
          </button>
        </div>
      </div>
      <div class="dashboard__aside absolute top-0 right-0 bottom-0 w-[30%] space-y-2 bg-gray-50 border-l py-3 px-4">
        <div class="flex items-center justify-between">
          <h2>Destacado</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <div class="text-[0.7em] leading-relaxed">
          <p>
            <small>
              <b>I.1. Mínimo rítmico y mínimo métrico</b> del libro
              <b>Ritmo y compás</b>
            </small>
          </p>
          <p class="bg-[#ffebcd] inline">
            En términos generales, podemos definir como polirrítmica toda
            simultaneidad de ritmos distintos.
          </p>
        </div>
        <div>
          <div class="px-4 py-2 flex items-center justify-between border rounded text-[0.6em] text-gray-500">
            <span>Añade etiquetas</span>
            <svg
              width="2em"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="pl-2 border-l text-gray-200"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </div>
        <div class="flex items-center justify-end text-[0.7em]">
          <button class="px-3">Cancelar</button>
          <button class="py-1 px-3 bg-fire-500 text-white block">
            Guardar
          </button>
        </div>
      </div>
    </>
  )
}

export default Book
