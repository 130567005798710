import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "./lib/Slider"
import PlanCard from "./PlanCard"

export default function PlanList({ withSlider, slidesToShow, filter }) {
  const { atril } = useStaticQuery(query)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow ? slidesToShow : 4,
    slidesToScroll: slidesToShow ? slidesToShow : 4,
    autoplay: true,
    autoplaySpeed: 4000,
  }

  if (withSlider)
    return (
      <Slider settings={settings}>
        {atril.plans
          .filter(plan =>
            filter ? plan.categoriaId === parseInt(filter) : plan
          )
          .map(plan => (
            <PlanCard
              key={plan.id}
              plan={plan}
              destacado={true}
              borderColor={
                atril.planCategorias?.find(
                  c => parseInt(c.id) === plan.categoriaId
                ).color
              }
            />
          ))}
      </Slider>
    )
  return (
    <div
      className={`grid grid-clos-2 sm:grid-cols-3 md:grid-cols-4 gap-5 py-8`}
    >
      {atril.plans
        .filter(plan => (filter ? plan.categoriaId === parseInt(filter) : plan))
        .map(plan => (
          <PlanCard
            key={plan.id}
            plan={plan}
            borderColor={
              atril.planCategorias.find(
                c => parseInt(c.id) === plan.categoriaId
              ).color
            }
          />
        ))}
    </div>
  )
}

const query = graphql`
  query {
    atril {
      plans {
        id
        titulo
        descripcion
        categoriaId
        duracion
        categoria
      }
      planCategorias {
        id
        name
        color
      }
    }
  }
`
