import React from "react"
import ContentSection from "../lib/ContentSection"

import photo1 from "./../../../static/bernat_jimenez_de_cisneros_puig.jpg"
import photo2 from "./../../../static/guillermo_castro.jpg"

export default function Autores() {
  return (
    <ContentSection
      title="Aunamos esfuerzos"
      text="Siempre desde el rigor científico y la documentación fehaciente."
      button="Autores"
      buttonPath="/autores"
      childrenTop
    >
      <div className="flex justify-center">
        <img
          className="w-40 h-40 rounded-full mr-10 mb-10 shadow-xl"
          // style={{ filter: "saturate(0)" }}
          src={photo1}
          alt="Foto de Bernat Jiménez de Cisneros Puig"
        />
        <img
          className="w-40 h-40 rounded-full mb-10 shadow-xl"
          // style={{ filter: "saturate(0)" }}
          src={photo2}
          alt="Foto de Guillermo Castro Buendía"
        />
      </div>
    </ContentSection>
  )
}
