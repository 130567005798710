import React from "react"
import ContentSection from "../lib/ContentSection"
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from "@atrilflamenco/react-components"
import BrowserLayout from "../lib/BrowserLayout"
import { micromark } from "micromark"

import book from "./../../../static/Book.png"
import search from "./../../../static/Buscar.png"
import nota from "./../../../static/Nota.png"
import ayuda from "./../../../static/Ayuda.png"

function getImage(id) {
  switch (id) {
    case "buscador":
      return search
    case "reproductor":
      return book
    case "notas":
      return nota
    case "guardados":
      return ayuda
    default:
      return undefined
  }
}

export default function Tools({ data }) {
  return (
    <ContentSection title={data.title}>
      <Tabs className="mx-auto">
        <TabList className="justify-center mx-auto">
          {data.items.map(item => (
            <Tab>{item.subtitle}</Tab>
          ))}
        </TabList>
        <TabPanels className="py-8">
          {data.items.map((item, index) => (
            <TabPanel>
              <div className="flex items-start flex-col lg:flex-row">
                <BrowserLayout className="max-w-screen-md mx-auto border-0" xs>
                  <img src={getImage(item.id)} alt={item.id} />
                </BrowserLayout>
                <ContentSection
                  title={item.title}
                  text={micromark(item.body)}
                  className="md:!text-left !px-3"
                />
              </div>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </ContentSection>
  )
}
