import React from "react"

function Section({ title, children, images, className }) {
  return (
    <section>
      <header className="py-2 flex justify-between border-b">
        <h2 className="text-[1em] font-semibold">{title}</h2>
        <buutton className="text-[0.6em]">Ver más</buutton>
      </header>
      <div className={`py-3 ${className}`}>
        {images && (
          <div className="grid grid-cols-3 gap-3">
            {images.map(src => (
              <div className="flex-1 bg-gray-100">
                <img src={src} alt="cover" />
              </div>
            ))}
          </div>
        )}
        {children}
      </div>
    </section>
  )
}

export default Section
