import React, { useLayoutEffect } from "react"
import SearchResultList from "./search-result-list"

function Drawer() {
  useLayoutEffect(() => {
    const selectedText = document.querySelectorAll(".search-text")
    selectedText.forEach(currentElement => {
      const splitedMarkup = currentElement.innerHTML
        .split("")
        .map(character => `<span>${character}</span>`)

      currentElement.innerHTML = splitedMarkup.join("")
    })
  }, [])

  return (
    <>
      <div className="drawer-background absolute top-0 left-0 right-0 bottom-0 bg-gray-100  opacity-0" />
      <div className="drawer-panel absolute w-[80%] sm:w-[60%] lg:w-[35%] h-full top-0 left-0 backdrop-blur-lg bg-[rgba(255,255,255,0.8)] pl-[var(--side-width)] pr-4">
        <div className="drawer__inner px-2 py-3">
          <header>
            <h3 className="text-[0.7em] text-gray text-sm font-semibold">
              Buscar
            </h3>
          </header>
          <div className="py-1">
            <div className="py-1 border-b border-gray-100 h-8 relative">
              <div>
                <span className="search-text input span:hidden">Bulerías</span>
                <span className="absolute inline-block w-[1px] h-6 bg-gray-500 animate-blink" />
              </div>
              <span className="delete-results text-[0.6em] text-gray-200 absolute top-2.5 right-0">
                borrar resultados
              </span>
            </div>
            <div className="suggested-list w-full bg-gray-50 rounded-bl rounded-br">
              <div className="text-xs px-2 py-2 border-b border-gray-100">
                <span className="search-text one">Bulería</span> rota
              </div>
              <div className="text-xs px-2 py-2 border-b border-gray-100">
                <span className="search-text two">Bulería</span> al golpe
              </div>
              <div className="text-xs px-2 py-2">
                Compás por <span className="search-text three">bulerías</span>
              </div>
            </div>
            <SearchResultList />
          </div>
        </div>
      </div>
    </>
  )
}

export default Drawer
