import React from "react"
import cover from "../../../../static/ritmoycompas.jpg"

function SearchResult({ recurso, capitulo, coincidencias }) {
  return (
    <div className="flex gap-5 items-center">
      <img src={cover} alt="cover" className="w-10" />
      <div>
        <div>
          <h6 className="text-[0.5em]">{recurso}</h6>
          <h3 className="text-[0.8em] font-semibold">{capitulo}</h3>
        </div>
        <div className="text-[0.4em]">{coincidencias} coincidencias</div>
      </div>
    </div>
  )
}

export default SearchResult
