import React from "react"
import styled from "styled-components"
import ContentSection from "../lib/ContentSection"
import Slider from "../lib/Slider"

const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 56px;
`

export default function Testimonals({ data }) {
  const slideSettings = {
    dots: true,
    responsive: [],
  }
  return (
    <ContentSection title={data.title} align="center">
      <Slider settings={slideSettings}>
        {data.items.map(testimonial => (
          <TestimonialCard key={testimonial.id}>
            <p className="leading-relaxed mb-5">{testimonial.body}</p>
            <small>
              <b>{testimonial.title}</b> {testimonial.subtitle}
            </small>
          </TestimonialCard>
        ))}
      </Slider>
    </ContentSection>
  )
}
