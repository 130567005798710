import React from "react"

function BrowserLayout({
  children,
  className,
  url = "app.atrilflamenco.com",
  xs,
}) {
  const Dot = () => (
    <div className={`${xs ? "w-2 h-2" : "w-3 h-3"} bg-white rounded-full`} />
  )

  return (
    <div className={`rounded shadow-2xl ${className}`}>
      <div
        className={`${
          xs ? "py-1 px-2" : "py-1.5 px-4"
        } flex justify-between items-center bg-gray-100 gap-2 rounded-t-sm`}
      >
        <div className="flex gap-1 ">
          {[...Array(3)].map(() => (
            <Dot />
          ))}
        </div>
        <div
          className={`${
            xs ? "h-3" : "h-6"
          } flex justify-center items-center bg-white w-full max-w-xl rounded-sm`}
        >
          <span className="text-[10px] font-semibold text-neutral-100">
            {url}
          </span>
        </div>
        <div className="flex gap-1 ">
          <Dot />
        </div>
      </div>
      {children}
    </div>
  )
}

export default BrowserLayout
