import React from "react"
import { Container } from "../lib/Container"
import ContentSection from "../lib/ContentSection"
import Branding from "../lib/Branding"
import BrowserLayout from "../lib/BrowserLayout"

import HeroWeb from "../HeroWeb"

export default function Hero({ data }) {
  return (
    <>
      <Container className="text-center pt-28 px-5">
        <div className="space-y-8 mb-28">
          <Branding />
          <h1 className="text-4xl font-semibold">{data.title}</h1>
          <h2 className="text-xl font-bold text-primary-500">
            {data.subtitle}
          </h2>
        </div>
        <BrowserLayout className="mb-10 max-w-screen-xl -mx-2 md:mx-auto border-0">
          <HeroWeb />
        </BrowserLayout>
      </Container>
    </>
  )
}
