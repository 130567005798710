import React from "react"
import ContentSection from "../lib/ContentSection"
import BookList from "../BookList"
import { tokens } from "@atrilflamenco/react-components"
import { micromark } from "micromark"

export default function Products({ data }) {
  return (
    <ContentSection
      title={data.title}
      subtitle={data.subtitle}
      text={micromark(data.body)}
      button="Ver lecturas"
      buttonPath="/lecturas"
      bgColor={tokens.colors.gray50}
    >
      <BookList withSlider slidesToShow={4} />
    </ContentSection>
  )
}
