import React from "react"
import ContentSection from "../lib/ContentSection"
import PlanList from "../PlanList"
import { tokens } from "@atrilflamenco/react-components"
import { micromark } from "micromark"

export default function Products({ data }) {
  return (
    <ContentSection
      title={data.title}
      subtitle={data.subtitle}
      text={micromark(data.body)}
      button="Ver más planes"
      buttonPath="/planes-de-lectura"
      bgColor={tokens.colors.gray50}
    >
      <PlanList withSlider slidesToShow={3} />
    </ContentSection>
  )
}
