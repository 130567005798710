import React from "react"

function Destacado({ chapter, recurso, children }) {
  return (
    <div>
      <div className="flex mb-2 items-center justify-between">
        <h4 className="text-xs">
          Nuevo destacado en <span className="font-semibold">{chapter}</span>{" "}
          del libro <span className="font-semibold">{recurso}</span>
        </h4>
        <span className="text-[0.8em]">hace 10 minutos</span>
      </div>
      <div className="leading-normal">{children}</div>
    </div>
  )
}

export default Destacado
