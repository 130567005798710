import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/sections/HeroSection"
import Autores from "../components/sections/AutoresSection"
import Products from "../components/sections/ProductsSection"
import Tools from "../components/sections/ToolsSection"
import Resource from "../components/sections/ResourceSection"
import Testimonials from "../components/sections/TestimonialsSection"
import CallToAction from "../components/sections/CallToActionSection"
import Footer from "../components/lib/Footer"
import HeroBottom from "../components/sections/HeroBottomSection"

export default function Home({ data }) {
  const sectionsData = data.allNotion.nodes
  const sections = sectionsData.reduce(
    (accumulator, currentValue) => (
      (accumulator[currentValue.title] = {
        id: currentValue.title,
        title: currentValue.properties.title.value,
        subtitle: currentValue.properties.subtitle.value,
        body: currentValue.properties.body.value,
      }),
      accumulator
    ),
    {}
  )

  return (
    <div className="pt-16">
      <Hero data={sections["hero"]} />
      <HeroBottom data={sections["hero-bottom"]} />
      <Autores data={sections["autores"]} />
      <Products data={sections["planes"]} />
      <Tools
        data={{
          ...sections["herramientas"],
          items: [
            sections["buscador"],
            sections["reproductor"],
            sections["notas"],
            sections["guardados"],
          ],
        }}
      />
      <Resource data={sections["recursos"]} />
      <Testimonials
        data={{
          ...sections["testimonials"],
          items: [sections["testimonial-1"], sections["testimonial-2"]],
        }}
      />
      <CallToAction />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    allNotion {
      nodes {
        title
        id
        properties {
          title {
            value
          }
          subtitle {
            value
          }
          body {
            value
          }
        }
      }
    }
  }
`
