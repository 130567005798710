import React from "react"
import { Navigation } from "."

const Layout = React.forwardRef(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      class={`dashboard grid grid-cols-[auto_1fr] relative mx-auto text-[1em] text-gray-800 bg-gray-50 h-[var(--dashboard-height)] rounded-br rounded-bl overflow-hidden ${className}`}
    >
      <div class="w-[var(--side-width)] h-[var(--dashboard-height)] relative z-10">
        <Navigation />
      </div>
      <div class="h-[var(--dashboard-height)] overflow-hidden text-left">
        {children}
      </div>
    </div>
  )
})

export default Layout
